import React from "react";
import { adj } from "../pages/Pain";
import { Alert } from "@mui/material";

type LinkDetailsProps = {
  linkDetails: adj[];
  getNodeName: (id: string) => string;
};

export const LinkDetails: React.FC<LinkDetailsProps> = ({
  linkDetails,
  getNodeName,
}) => {
  return (
    <>
      {linkDetails.map((linkDetail, index) => (
        <React.Fragment key={index}>
          <Alert severity="warning">
            Port numbers may be 1 lower in real life
          </Alert>
          <table>
            <thead>
              <tr>
                <th>Node</th>
                <th>Interface</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {linkDetail.local
                    ? getNodeName(linkDetail.local.node ?? "")
                    : "N/A"}
                </td>
                <td>
                  {linkDetail.local
                    ? `${linkDetail.local.card}/${linkDetail.local.port}`
                    : "N/A"}
                </td>
              </tr>
              {linkDetail.adjacents?.map((adjacent, index) => (
                <tr key={index}>
                  <td>{getNodeName(adjacent.node ?? "")}</td>
                  <td>{`${adjacent.card}/${adjacent.port}`}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </React.Fragment>
      ))}
    </>
  );
};
