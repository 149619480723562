import { Adjacency, AdjacencyPair } from "../../gen/painClient/types.gen";
import { configureOpenAPI } from "../../utils/openApi";
import { OpenAPI } from "../../gen/painClient/core/OpenAPI";
import { getNodeAdjacencies } from "../../gen/painClient/sdk.gen";

export type NodeAdjacencies = {
    local: Adjacency | undefined;
    adjacents: Adjacency[] | undefined;
  }[];
  
  const sortPorts = (a: string, b: string) => {
    const parsePort = (port: string) =>
      port.split("/").map((part, index) => (index === 0 ? part : Number(part)));
    const [aParts, bParts] = [parsePort(a), parsePort(b)];
    for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
      if (aParts[i] !== bParts[i]) {
        if (i === 0) {
          // Lexical comparison for the first part
          return (aParts[i] as string).localeCompare(bParts[i] as string);
        } else {
          // Numerical comparison for the remaining parts
          return (aParts[i] as number) - (bParts[i] as number);
        }
      }
    }
    return 0;
  };
  
  const processAdjacencies = (
    adjacencies: AdjacencyPair[],
    nodeId: string,
    sorted?: boolean,
  ): NodeAdjacencies => {
    const processed = adjacencies.map((pair) => {
      const localNode = pair.find((node) => node.node === nodeId);
      const adjacentNodes = pair.filter((node) => node.node !== nodeId);
      return { local: localNode, adjacents: adjacentNodes };
    });
  
    if (sorted) {
      // Sort the processed adjacencies based on the card and port of the local nodes
      processed.sort((a, b) => {
        if (a.local && b.local) {
          return sortPorts(
            a.local.card + "/" + a.local.port,
            b.local.card + "/" + b.local.port,
          );
        }
        return 0;
      });
    }
    return processed;
  };
  
  export const fetchPainAdjacencies = async (
    nodeId: string,
    sorted?: boolean,
  ): Promise<NodeAdjacencies> => {
    await configureOpenAPI(OpenAPI);
    const response = await getNodeAdjacencies({
      nodeId: nodeId,
    });
    const processedResults = response.adjacencies
      ? processAdjacencies(response.adjacencies, nodeId, sorted)
      : [] as NodeAdjacencies;
  
    return processedResults;
  };

  export default fetchPainAdjacencies;