import React, { useRef, useEffect, useState } from "react";
import { GraphEdge, GraphNode } from "./networkGraph";
import { Button, ListItem, ListItemText, TextField } from "@mui/material";

type NodeListPanelProps = {
  nodes: GraphNode[];
  inViewNodes: GraphNode[];
  onSelectNode: (node: GraphNode) => void;
  onselectNodeinView?: (node: GraphNode) => void;
  toggleDrawer: (open: boolean) => void; // Add toggleDrawer prop
};

type EdgeListPanelProps = {
  edges: GraphEdge[];
  inViewEdges: GraphEdge[];
  onSelectEdge: (edge: GraphEdge) => void;
  toggleDrawer: (open: boolean) => void;
};

export const NodeListPanel: React.FC<NodeListPanelProps> = ({
  nodes,
  inViewNodes,
  onSelectNode,
  onselectNodeinView,
  toggleDrawer, // Destructure toggleDrawer prop
}) => {
  const [maxWidth, setMaxWidth] = useState(200); // Default width
  const containerRef = useRef<HTMLDivElement>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [viewAll, setViewAll] = useState(false);

  const viewNodes = () => {
    if (viewAll) {
      return nodes;
    }
    return inViewNodes;
  };

  // Sort nodes alphabetically based on the preferred node name
  const sortedNodes = [...viewNodes()].sort((a, b) => {
    const nameA = a.label || a.id;
    const nameB = b.label || b.id;
    return nameA.localeCompare(nameB);
  });

  // Filter nodes based on search term
  const filteredNodes = sortedNodes.filter((node) => {
    const query = searchTerm.toLowerCase();
    return (
      (node.label || node.id).toLowerCase().includes(query) ||
      node.id.toLowerCase().includes(query)
    );
  });

  useEffect(() => {
    if (containerRef.current) {
      const widths = sortedNodes.map((node) => {
        const span = document.createElement("span");
        span.style.visibility = "hidden";
        span.style.position = "absolute";
        span.style.whiteSpace = "nowrap";
        span.textContent = node.label || node.id;
        document.body.appendChild(span);
        const width = span.offsetWidth;
        document.body.removeChild(span);
        return width;
      });
      const maxWidth = Math.max(...widths);
      setMaxWidth(maxWidth + 20); // Add some padding
    }
  }, [sortedNodes]);

  return (
    <div
      ref={containerRef}
      style={{
        width: `${maxWidth}px`,
        overflowY: "auto",
        borderRight: "1px solid #ccc",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TextField
        label="Find Node"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ marginBottom: "10px" }}
      />
      <Button
        variant="outlined"
        onClick={() => setViewAll(!viewAll)}
        style={{ marginBottom: "10px" }}
      >
        {viewAll ? "View displayed" : "View All"}
      </Button>
      {filteredNodes.map((node) => (
        <ListItem
          component="div"
          key={node.id}
          onClick={() => {
            if (!viewAll && onselectNodeinView) {
              onselectNodeinView(node);
              return;
            }
            onSelectNode(node);
            toggleDrawer(false); // Close the drawer when a node is selected
          }}
          style={{ cursor: "pointer" }}
        >
          <ListItemText primary={node.label || node.id} secondary={node.id} />
        </ListItem>
      ))}
    </div>
  );
};

export const EdgeListPanel: React.FC<EdgeListPanelProps> = ({
  edges,
  inViewEdges,
  onSelectEdge,
  toggleDrawer,
}) => {
  const [maxWidth, setMaxWidth] = useState(200);
  const containerRef = useRef<HTMLDivElement>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [viewAll, setViewAll] = useState(false);

  const viewEdges = () => {
    if (viewAll) {
      return edges;
    }
    return inViewEdges;
  };

  const sortedEdges = [...viewEdges()].sort((a, b) => {
    const nameA = a.label || a.id;
    const nameB = b.label || b.id;
    return nameA.localeCompare(nameB);
  });

  const filteredEdges = sortedEdges.filter((edge) =>
    (edge.label || edge.id).toLowerCase().includes(searchTerm.toLowerCase()),
  );

  useEffect(() => {
    if (containerRef.current) {
      const widths = sortedEdges.map((edge) => {
        const span = document.createElement("span");
        span.style.visibility = "hidden";
        span.style.position = "absolute";
        span.style.whiteSpace = "nowrap";
        span.textContent = edge.label || edge.id;
        document.body.appendChild(span);
        const width = span.offsetWidth;
        document.body.removeChild(span);
        return width;
      });
      const maxWidth = Math.max(...widths);
      setMaxWidth(maxWidth + 50);
    }
  }, [sortedEdges]);

  return (
    <div
      ref={containerRef}
      style={{
        width: `${maxWidth}px`,
        overflowY: "auto",
        borderRight: "1px solid #ccc",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TextField
        label="Find Edge"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ marginBottom: "10px" }}
      />
      {filteredEdges.map((edge) => (
        <ListItem
          component="div"
          key={edge.id}
          onClick={() => {
            onSelectEdge(edge);
            toggleDrawer(false); // Close the drawer when a node is selected
          }}
          style={{ cursor: "pointer" }}
        >
          <ListItemText primary={edge.label || edge.id} />
        </ListItem>
      ))}
    </div>
  );
};

export default NodeListPanel;
