import React, { useState } from "react";
import ReactDOM from "react-dom";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import { ThemeContextProvider } from "../styles/themeContext";
import {
  Paper,
  MenuItem,
  Typography,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LanIcon from "@mui/icons-material/Lan";
import { CenterFocusStrong } from "@mui/icons-material";
import { GraphNode } from "./networkGraph";

const NgContextMenu = ({ x, y, children }) => {
  const handleClick = (event) => {
    event.stopPropagation();
  };
  return ReactDOM.createPortal(
    <ThemeContextProvider>
      <Paper
        style={{
          position: "absolute",
          left: x,
          top: y,
          zIndex: 1000,
          padding: "10px",
        }}
        onClick={handleClick}
      >
        {children}
      </Paper>
    </ThemeContextProvider>,
    document.body,
  );
};

export const NgNodeContextMenu = ({
  x,
  y,
  onClose,
  onJumpToNeighborhood,
  onFocusOnNode,
  node,
}: {
  x: number;
  y: number;
  onClose: (id) => void;
  onJumpToNeighborhood: (id) => void;
  onFocusOnNode: (id) => void;
  node: GraphNode;
}) => {
  return ReactDOM.createPortal(
    <NgContextMenu x={x} y={y}>
      <Typography variant="h6" gutterBottom>
        Node Actions
      </Typography>
      <MenuItem onClick={onJumpToNeighborhood}>
        <ShortcutIcon style={{ marginRight: "5px" }} />
        <LanIcon style={{ marginRight: "5px" }} />
        <div style={{ fontSize: "12px" }}>
          {node.label || node.id} Neighborhood
        </div>
      </MenuItem>
      <MenuItem onClick={onFocusOnNode}>
        <CenterFocusStrong style={{ marginRight: "5px" }} />
        <div style={{ fontSize: "12px" }}>Focus on Node</div>
      </MenuItem>
      <MenuItem onClick={onClose}>
        <CloseIcon />
        <div style={{ fontSize: "12px" }}>Close</div>
      </MenuItem>
    </NgContextMenu>,
    document.body,
  );
};

export const NgBackgroundContextMenu = ({
  x,
  y,
  onClose,
  onFocusNode,
  nodes,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredNodes, setFilteredNodes] = useState(nodes);

  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    setFilteredNodes(
      nodes
        .filter(
          (node) =>
            (node.label && node.label.toLowerCase().includes(query)) ||
            node.id.toLowerCase().includes(query),
        )
        .sort((a, b) => {
          const labelA = a.label ? a.label.toLowerCase() : a.id.toLowerCase();
          const labelB = b.label ? b.label.toLowerCase() : b.id.toLowerCase();
          if (labelA < labelB) return -1;
          if (labelA > labelB) return 1;
          return a.id.toLowerCase().localeCompare(b.id.toLowerCase());
        }),
    );
  };

  const handleNodeSelect = (nodeId) => {
    onFocusNode(nodeId);
    onClose();
  };

  return ReactDOM.createPortal(
    <NgContextMenu x={x} y={y}>
      <Typography variant="h6" gutterBottom>
        Background Actions
      </Typography>
      <TextField
        label="Search Nodes"
        variant="outlined"
        size="small"
        value={searchQuery}
        onChange={handleSearchChange}
        fullWidth
        style={{ marginBottom: "10px" }}
      />
      <List>
        {filteredNodes.map((node) => (
          <ListItem
            component="div"
            key={node.id}
            onClick={() => handleNodeSelect(node.id)}
            style={{ cursor: "pointer" }}
          >
            <ListItemText primary={node.label || node.id} secondary={node.id} />
          </ListItem>
        ))}
      </List>
    </NgContextMenu>,
    document.body,
  );
};
