import * as d3 from "d3";
import React from "react";
import ConvertComponentToDataURL from "../utils/convertComponentToDataUrl";
import {
  SwitchIcon,
  MPLSRouterIcon,
  ServiceRouterIcon,
  GenericSoftSwitchIcon,
} from "./ServiceTopologyIcons";
import { Theme } from "@mui/material/styles";
import { NgBackgroundContextMenu, NgNodeContextMenu } from "./ngContextMeny";
import { createRoot } from "react-dom/client";
import { on } from "events";

export type Layout = "default" | "Tree" | "Radial" | "ForceDirected" | "Grid";
// | "Cluster";
export type NodeIcon = "switch" | "mplsRouter" | "serviceRouter" | "unknown";

export type GraphData = {
  title: string;
  nodes: GraphNode[];
  edges: GraphEdge[];
};

export type GraphNode = {
  id: string;
  title?: string;
  label?: string;
  color?: string;
  x?: number;
  y?: number;
  icon?: NodeIcon;
  physics?: boolean;
  weight?: number;
  group?: string;
};

export type GraphEdge = {
  source: GraphNode | string;
  target: GraphNode | string;
  id: string;
  label?: string;
  color?: string;
  width?: number;
  title?: string;
  from: string;
  to: string;
};

type iconLookup = { [key in NodeIcon]: string };

const maxSimulateNodes = 1000;
const maxSimulateEdges = 2000;

export class NetworkGraph {
  main: d3.Selection<SVGSVGElement, unknown, null, undefined>;
  container: d3.Selection<SVGGElement, unknown, null, undefined>;
  nodes: GraphNode[];
  edges: GraphEdge[];
  zoom: d3.ZoomBehavior<Element, unknown>;
  width: number;
  height: number;
  private viewOnlyNeighborhood: boolean;
  private focusedNode: string;
  private graphRef: React.RefObject<HTMLDivElement>;
  private setSelectedLink: (link: any) => void;
  private setSelectedNode: (node: any) => void;
  private layout: Layout;
  private theme: Theme;
  private icons: iconLookup;
  private contextMenu: any = null;
  private depth: number;
  private filteredNodes: GraphNode[];
  private filteredEdges: GraphEdge[];

  constructor({
    graphData,
    graphRef,
    setSelectedLink,
    setSelectedNode,
    layout,
    theme,
    viewOnlyNeighborhood,
    focusedNode,
    depth,
  }: {
    graphData: GraphData;
    graphRef: React.RefObject<HTMLDivElement>;
    setSelectedLink: (link: any) => void;
    setSelectedNode: (node: any) => void;
    layout: Layout;
    theme: Theme;
    viewOnlyNeighborhood?: boolean;
    focusedNode?: string;
    depth?: number;
  }) {
    this.graphRef = graphRef;
    this.setSelectedLink = setSelectedLink;
    this.setSelectedNode = setSelectedNode;
    this.layout = layout;
    this.theme = theme;
    this.viewOnlyNeighborhood = viewOnlyNeighborhood || false;
    this.focusedNode = focusedNode || "";
    this.depth = depth || 2;

    this.main = d3
      .select(this.graphRef.current)
      .append("svg")
      .attr("width", "100%")
      .attr("height", "100%");
    this.container = this.main.append("g");

    // Ensure each node has a unique id
    this.nodes =
      graphData?.nodes?.map((node) => ({
        ...node,
        id: node.id ? node.id.toString() : "",
      })) || [];
    this.filteredNodes = this.nodes;

    // Ensure edges reference valid node ids
    this.edges =
      graphData?.edges?.map((edge) => ({
        ...edge,
        from: (edge.from ?? "").toString(),
        to: (edge.to ?? "").toString(),
      })) || [];
    this.filteredEdges = this.edges;

    this.zoom = d3
      .zoom()
      .scaleExtent([0.5, 5]) // Set the zoom scale extent
      .on("zoom", (event) => {
        this.container.attr("transform", event.transform);
      });
    this.width = this.graphRef?.current?.clientWidth || 0;
    this.height = this.graphRef?.current?.clientHeight || 0;
  }

  async loadIcons(): Promise<void> {
    const switchIcon = await ConvertComponentToDataURL(SwitchIcon);
    const mplsRouterIcon = await ConvertComponentToDataURL(MPLSRouterIcon);
    const serviceRouterIcon =
      await ConvertComponentToDataURL(ServiceRouterIcon);
    const unknownIcon = await ConvertComponentToDataURL(() => (
      <GenericSoftSwitchIcon fill={"#808080"} />
    ));
    this.icons = {
      switch: switchIcon,
      mplsRouter: mplsRouterIcon,
      serviceRouter: serviceRouterIcon,
      unknown: unknownIcon,
    } as iconLookup;
  }

  selectNodeIcon = (icon: NodeIcon) => {
    switch (icon) {
      case "serviceRouter":
        return this.icons.serviceRouter;
      case "mplsRouter":
        return this.icons.mplsRouter;
      case "switch":
        return this.icons.switch;
    }
    return this.icons.unknown;
  };

  async renderNetwork(): Promise<void> {
    if (!this.main) {
      return;
    }
    if (!this.graphRef.current) {
      return;
    }
    if (!this.nodes) {
      return;
    }
    if (!this.edges) {
      return;
    }
    if (!this.theme) {
      return;
    }
    if (this.viewOnlyNeighborhood && this.focusedNode) {
      // check if focusnode is in the nodes
      if (
        this.nodes.find((node) => {
          if (node.id === this.focusedNode) {
            return true;
          }
          return false;
        })
      ) {
        return this.viewNeighborhood(this.focusedNode);
      }
      // if not check if focusnode is really an edge and get the node from the edge
      if (
        this.filteredEdges.find((edge) => {
          if (edge.id === this.focusedNode) {
            this.focusedNode = edge.from;
          }
        })
      ) {
        return this.viewNeighborhood(this.focusedNode);
      }
      // if its not a valid node or edge return the whole network
      return this.doRenderNetwork();
    }
    return this.doRenderNetwork();
  }

  private async doRenderNetwork(): Promise<void> {
    await this.loadIcons();
    if (this.graphRef.current) {
      this.main.call(this.zoom);

      // Clear the network before rendering
      this.clearNetwork();

      // Create a map of node IDs to nodes
      const nodeMap = new Map(
        this.filteredNodes.map((node) => [node.id, node]),
      );

      // Ensure all edges reference valid nodes
      this.filteredEdges = this.edges.filter(
        (edge) => nodeMap.has(edge.from) && nodeMap.has(edge.to),
      );

      const resetNodePositions = (nodes: GraphNode[]) => {
        nodes.forEach((node) => {
          node.x = undefined;
          node.y = undefined;
        });
      };

      const applyTreeLayout = (nodes: GraphNode[], edges: GraphEdge[]) => {
        return d3
          .forceSimulation(nodes)
          .force(
            "link",
            d3
              .forceLink(edges)
              .id((d: GraphNode) => d.id)
              .distance(120),
          )
          .force(
            "pullToTop",
            d3.forceY(0).strength((d) => d.weight * 0.5 || 0.5), // Stronger attraction for nodes with larger weights
          )
          .force("center", d3.forceCenter(this.width / 2, this.height / 2))
          .force(
            "charge",
            d3.forceManyBody().strength((d) => d.weight * -300 || -300), // Increase repulsion for heavier nodes
          ); // Increase repulsion
      };

      const applyRadialLayout = (nodes: GraphNode[], edges: GraphEdge[]) => {
        const centerX = this.width / 2;
        const centerY = this.height / 2;

        // Node radius (example size)
        const rNode = 20; // Radius of each node

        // Calculate the angle increment based on the number of nodes
        const angleIncrement = (2 * Math.PI) / nodes.length;

        // Calculate the minimum distance required to avoid overlap (2 * rNode)
        const minDistance = 2 * rNode;

        // Calculate the radius of the circle to avoid overlap
        const radius = minDistance / (2 * Math.sin(angleIncrement / 2));

        // Set initial positions for nodes in a radial layout
        nodes.forEach((node, index) => {
          const angle = index * angleIncrement;
          node.x = centerX + radius * Math.cos(angle);
          node.y = centerY + radius * Math.sin(angle);
        });

        // Create and return a simulation
        return d3
          .forceSimulation(nodes)
          .force("radial", d3.forceRadial(radius, centerX, centerY).strength(1))
          .force(
            "collision",
            d3.forceCollide().radius(rNode), // Use the node's radius for collision
          )
          .force("center", d3.forceCenter(centerX, centerY)) // Ensures nodes stay centered
          .force(
            "link",
            d3
              .forceLink(edges)
              .id((d: GraphNode) => d.id)
              .strength(0),
          ); // Add links without any strength
      };

      const applyForceDirectedLayout = (
        nodes: GraphNode[],
        edges: GraphEdge[],
      ) => {
        return d3
          .forceSimulation(nodes)
          .force(
            "link",
            d3
              .forceLink(edges)
              .id((d: GraphNode) => d.id)
              .distance(120),
          )
          .force(
            "charge",
            d3.forceManyBody().strength((d) => d.weight * -300 || -300), // Increase repulsion for heavier nodes
          )
          .force("center", d3.forceCenter(this.width / 2, this.height / 2));
      };

      const applyGridLayout = (nodes: GraphNode[], edges: GraphEdge[]) => {
        resetNodePositions(nodes);
        const cols = Math.ceil(Math.sqrt(nodes.length));
        const rows = Math.ceil(nodes.length / cols);
        const cellWidth = this.width / cols;
        const cellHeight = this.height / rows;

        // Manually set the positions of the nodes
        nodes.forEach((node, index) => {
          const col = index % cols;
          const row = Math.floor(index / cols);
          node.x = col * cellWidth + cellWidth / 2;
          node.y = row * cellHeight + cellHeight / 2;
        });

        return (
          d3
            .forceSimulation(nodes)
            .force("center", d3.forceCenter(this.width / 2, this.height / 2))
            .force(
              "link",
              d3
                .forceLink(edges)
                .id((d: GraphNode) => d.id)
                .strength(0),
            ) // Add links without any strength
            // .alpha(1) // Start with a high alpha value to ensure the simulation runs
            .restart()
        );
      };

      const applyClusterLayout = (nodes: GraphNode[], edges: GraphEdge[]) => {
        const clusters = d3.group(
          nodes,
          (d: GraphNode) => d.group || "default",
        ); // Group nodes by a 'group' attribute, default to 'default' if undefined

        // Create cluster nodes
        const clusterNodes = Array.from(clusters.keys()).map((group, i) => ({
          id: `cluster-${group}`,
          group,
          x: (i + 1) * (this.width / (clusters.size + 1)),
          y: this.height / 2,
        }));

        // Create cluster edges
        const clusterEdges = edges.flatMap((edge) => {
          const fromGroup = nodes.find((n) => n.id === edge.source)?.group;
          const toGroup = nodes.find((n) => n.id === edge.target)?.group;
          return {
            source: `cluster-${fromGroup}`,
            target: `cluster-${toGroup}`,
          };
        });

        // Create a force simulation for the clusters
        const clusterSimulation = d3
          .forceSimulation(clusterNodes)
          .force(
            "link",
            d3
              .forceLink(clusterEdges)
              .id((d: any) => d.id)
              .distance(200),
          )
          .force("charge", d3.forceManyBody().strength(-300))
          .force("center", d3.forceCenter(this.width / 2, this.height / 2));

        // Store node simulations
        const nodeSimulations = [];

        // Apply force-directed layout to nodes within each cluster
        clusters.forEach((nodes, group) => {
          const clusterNode = clusterNodes.find(
            (d) => d.id === `cluster-${group}`,
          );
          if (!clusterNode) return;

          const nodeSimulation = d3
            .forceSimulation(nodes)
            .force(
              "link",
              d3
                .forceLink(
                  edges.filter(
                    (e) =>
                      (e.source as GraphNode).group === group &&
                      (e.target as GraphNode).group === group,
                  ),
                )
                .id((d: GraphNode) => d.id)
                .distance(50),
            )
            .force("charge", d3.forceManyBody().strength(-50))
            .force("center", d3.forceCenter(clusterNode.x, clusterNode.y))
            .alpha(1)
            .restart();

          nodeSimulations.push(nodeSimulation);
        });

        // Update positions on each tick
        clusterSimulation.on("tick", () => {
          nodeSimulations.forEach((simulation) => simulation.tick());
        });

        return clusterSimulation;
      };

      const applyLayout = (layout, nodes, edges) => {
        switch (layout) {
          case "Tree":
            return applyTreeLayout(nodes, edges);
          case "Radial":
            return applyRadialLayout(nodes, edges);
          case "ForceDirected":
            return applyForceDirectedLayout(nodes, edges);
          case "Grid":
            return applyGridLayout(nodes, edges);
          // case "Cluster":
          //   return applyClusterLayout(nodes, edges);
          default:
            return applyTreeLayout(nodes, edges);
        }
      };

      const simulation = applyLayout(
        this.layout,
        this.filteredNodes,
        this.filteredEdges,
      );

      // Append background rectangle for capturing click events
      this.container
        .append("rect")
        .attr("class", "background")
        .attr("width", this.width)
        .attr("height", this.height)
        .style("fill", "none")
        .style("pointer-events", "all")
        .on("click", () => {
          this.deselectAll();
        })
        .on("contextmenu", (event) => {
          event.preventDefault(); // Prevent the default context menu from appearing
          this.showContextMenu(event, null); // Show custom context menu
        });

      const link = this.container
        .append("g")
        .attr("class", "links")
        .selectAll("g")
        .data(this.filteredEdges)
        .enter()
        .append("g");

      // Append visible line
      link
        .append("line")
        .attr("class", "link-line")
        .attr("stroke", "#999")
        .attr("stroke-opacity", 0.6)
        .attr("stroke-width", (d: GraphEdge) => d.width)
        .attr("link-id", (d: GraphEdge) => d.id);
      // Append invisible line for capturing click events on top of the visible line
      link
        .append("line")
        .attr("class", "clickable-line")
        .attr("stroke", "transparent")
        .attr("stroke-width", (d: GraphEdge) => Math.max(10, d.width ?? 0))
        .on("click", (_, d: GraphEdge) => {
          this.selectEdge(d.id);
        });

      const node = this.container
        .append("g")
        .attr("class", "nodes")
        .selectAll("g")
        .data(this.filteredNodes)
        .enter()
        .append("g")
        .attr("x", (d) => d.x)
        .attr("y", (d) => d.y)
        .attr("class", "node-group")
        .attr("data-id", (d) => d.id);

      // Append image
      node
        .append("image")
        .attr("xlink:href", (d) => this.selectNodeIcon(d.icon))
        .attr("width", 20)
        .attr("height", 20)
        .on("click", (_, d) => {
          this.selectNode(d.id);
        })
        .on("dblclick", (_, d) => {
          if (this.viewOnlyNeighborhood) {
            this.viewNeighborhood(d.id); // Focus on the selected node and its neighbors
          }
        })
        .on("contextmenu", (event, d) => {
          event.preventDefault(); // Prevent the default context menu from appearing
          this.showContextMenu(event, d); // Show custom context menu
        })
        .call(
          d3
            .drag()
            .on("start", (event, d) => {
              this.zoom.on("zoom", null); // Disable zooming
              simulation.stop(); // Stop the simulation
              event.sourceEvent.stopPropagation(); // Prevent zooming
              if (!event.active) simulation.alphaTarget(0.3).restart();
              d.fx = d.x;
              d.fy = d.y;
            })
            .on("drag", (event, d) => {
              event.sourceEvent.stopPropagation(); // Prevent zooming
              d.fx = event.x;
              d.fy = event.y;
            })
            .on("end", (event, d) => {
              this.zoom.on("zoom", (event) => {
                this.container.attr("transform", event.transform);
              }); // Re-enable zooming
              simulation.restart(); // Restart the simulation
              event.sourceEvent.stopPropagation(); // Prevent zooming
              if (!event.active) simulation.alphaTarget(0);
              d.fx = null;
              d.fy = null;
            }),
        );

      // Append node label
      node
        .append("text")
        .attr("class", "node-label")
        .text((d) => d.label)
        .style("font-size", "12px")
        .style("fill", this.theme?.palette?.text?.secondary ?? "#808080")
        .style("pointer-events", "none"); // Disable pointer events

      simulation.on("tick", () => {
        link
          .selectAll("line")
          .attr("x1", (d) => d.source.x)
          .attr("y1", (d) => d.source.y)
          .attr("x2", (d) => d.target.x)
          .attr("y2", (d) => d.target.y);

        link
          .selectAll(".link-label")
          .attr("x", (d) => (d.source.x + d.target.x) / 2)
          .attr("y", (d) => (d.source.y + d.target.y) / 2);

        node
          .selectAll(".selected-circle")
          .attr("cx", (d) => d.x)
          .attr("cy", (d) => d.y);

        node
          .selectAll("image")
          .attr("x", (d) => d.x - 10)
          .attr("y", (d) => d.y - 10);

        node
          .selectAll(".node-label")
          .attr("x", (d) => d.x + 15)
          .attr("y", (d) => d.y + 10);

        node
          .selectAll(".node-id")
          .attr("x", (d) => d.x + 15)
          .attr("y", (d) => d.y + 25);

        setTimeout(() => {
          if (
            this.filteredNodes.length > maxSimulateNodes ||
            this.filteredEdges.length > maxSimulateEdges
          ) {
            simulation.stop();
            this.fitScreen();
          }
        }, 10000);
      });
      simulation.on("end", () => {
        this.fitScreen();
      });
    }
  }

  private showContextMenu(event, node: GraphNode | null) {
    event.preventDefault(); // Prevent the default context menu from appearing

    // Close any existing context menu to prevent multiple menus from appearing
    if (this.contextMenu) {
      this.contextMenu.unmount();
      const existingContainer = document.getElementById("context-menu");
      if (existingContainer) {
        document.body.removeChild(existingContainer);
      }
      this.contextMenu = null;
    }

    const contextMenuContainer = document.createElement("div");
    contextMenuContainer.id = "context-menu";
    document.body.appendChild(contextMenuContainer);

    this.contextMenu = createRoot(contextMenuContainer);

    const onClose = () => {
      const container = document.getElementById("context-menu");
      if (container) {
        this.contextMenu.unmount();
        document.body.removeChild(container);
        this.contextMenu = null;
      }
    };

    const onFocusNode = (id: string) => {
      if (!this.viewOnlyNeighborhood) {
        focusOnNode(id);
        return;
      }
      this.viewNeighborhood(id); // Call the viewNeighborhood method
      onClose();
    };

    const focusOnNode = (id: string) => {
      this.selectNode(id, true);
      onClose();
    };

    if (node) {
      this.contextMenu.render(
        <NgNodeContextMenu
          x={event.pageX}
          y={event.pageY}
          node={node}
          onClose={onClose}
          onJumpToNeighborhood={() => this.viewNeighborhood(node.id)}
          onFocusOnNode={() => focusOnNode(node.id)}
        />,
      );
    } else {
      this.contextMenu.render(
        <NgBackgroundContextMenu
          x={event.pageX}
          y={event.pageY}
          nodes={this.nodes}
          onClose={onClose}
          onFocusNode={() => onFocusNode(node?.id ?? "")}
        />,
      );
    }

    // Remove the menu when clicking outside
    document.addEventListener(
      "click",
      () => {
        if (contextMenuContainer.parentNode) {
          this.contextMenu.unmount();
          document.body.removeChild(contextMenuContainer);
        }
      },
      { once: true },
    );
  }

  setViewOnlyNeighborhood(viewOnlyNeighborhood: boolean): void {
    this.viewOnlyNeighborhood = viewOnlyNeighborhood;
  }

  setDepth(depth: number): void {
    this.depth = depth;
  }

  getFilteredNodes(): GraphNode[] {
    return this.filteredNodes;
  }

  getFilteredEdges(): GraphEdge[] {
    return this.filteredEdges;
  }

  async viewNeighborhood(selectedNodeId: string): Promise<void> {
    if (!selectedNodeId || selectedNodeId === "") {
      return;
    }
    if (!this.nodes || !this.edges) {
      return;
    }
    // Step 1: Filter nodes
    const neighbors = new Set<string>();

    // Find immediate neighbors
    neighbors.add(selectedNodeId);

    for (let i = 0; i < this.depth; i++) {
      const tmpNeigh = new Set<string>();
      this.edges.forEach((edge) => {
        if (neighbors.has(edge.from)) {
          tmpNeigh.add(edge.to);
        }
        if (neighbors.has(edge.to)) {
          tmpNeigh.add(edge.from);
        }
      });
      tmpNeigh.forEach((node) => neighbors.add(node));
    }

    const filteredNodes = this.nodes.filter((node) => neighbors.has(node.id));

    // Step 2: Filter edges
    const filteredEdges = this.edges.filter(
      (edge) => neighbors.has(edge.from) && neighbors.has(edge.to),
    );

    this.filteredNodes = filteredNodes;
    this.filteredEdges = filteredEdges;

    // Re-render the graph
    await this.doRenderNetwork();
    this.selectNode(selectedNodeId);
  }

  async reRenderNetwork({
    layout,
    theme,
    viewOnlyNeighborhood,
  }: {
    layout: Layout;
    theme: Theme;
    viewOnlyNeighborhood?: boolean;
  }): Promise<void> {
    this.layout = layout;
    this.theme = theme;
    if (viewOnlyNeighborhood !== undefined) {
      this.viewOnlyNeighborhood = viewOnlyNeighborhood;
    }

    await this.renderNetwork();
  }

  clearNetwork(): void {
    if (!this.container) {
      return;
    }
    this.container.selectAll("*").remove();
  }

  destroyNetwork(): void {
    if (!this.main) {
      return;
    }
    this.main.remove();
  }

  fitScreen() {
    if (!this.filteredNodes || !this.main) {
      return;
    }
    // only consider nodes currently rendered

    // Calculate the bounding box of the nodes
    const nodesBBox = this.filteredNodes.reduce(
      (bbox, node) => {
        const { x, y } = node;
        bbox.minX = Math.min(bbox.minX, x);
        bbox.maxX = Math.max(bbox.maxX, x);
        bbox.minY = Math.min(bbox.minY, y);
        bbox.maxY = Math.max(bbox.maxY, y);
        return bbox;
      },
      {
        minX: Infinity,
        maxX: -Infinity,
        minY: Infinity,
        maxY: -Infinity,
      },
    );

    // Define the margin
    const margin = 20;

    // Calculate the width and height of the bounding box with margin
    const bboxWidth = nodesBBox.maxX - nodesBBox.minX + 2 * margin;
    const bboxHeight = nodesBBox.maxY - nodesBBox.minY + 2 * margin;

    // Calculate the scale and translation to fit all nodes within the viewBox
    const scale = Math.min(this.width / bboxWidth, this.height / bboxHeight);
    const translateX =
      (this.width - bboxWidth * scale) / 2 - (nodesBBox.minX - margin) * scale;
    const translateY =
      (this.height - bboxHeight * scale) / 2 -
      (nodesBBox.minY - margin) * scale;

    // Apply the zoom transformation
    this.main
      .transition()
      .duration(750)
      .call(
        this.zoom.transform,
        d3.zoomIdentity.translate(translateX, translateY).scale(scale),
      );
  }

  deselectAll() {
    this.deselectNode();
    this.deselectEdge();
  }

  deselectEdge() {
    // Clear previous selections
    this.main.selectAll(".link-line").attr("stroke-opacity", 0.4);
    this.main.selectAll(".link-label").remove();
    this.setSelectedLink(null);
  }

  selectEdge(linkId: string, focus?: boolean) {
    if (this.edges === null) {
      return;
    }
    // Clear previous selections
    this.deselectEdge();

    // Find the link to select
    const selectedLink = this.edges.find((edge) => edge.id === linkId);

    if (selectedLink && selectedLink.source && selectedLink.target) {
      // call the setSelectedLink function
      this.setSelectedLink(selectedLink);
      // Highlight the link
      const linkGroup = this.container.select(`[link-id='${selectedLink.id}']`);
      // set opacity of selected link to 1
      linkGroup.attr("stroke-opacity", 1);

      // Append text for link label between the source and target nodes
      d3.select(linkGroup.node().parentNode)
        .append("text")
        .attr("class", "link-label")
        .attr("x", (selectedLink.source.x + selectedLink.target.x) / 2)
        .attr("y", (selectedLink.source.y + selectedLink.target.y) / 2)
        .text(selectedLink.label)
        .style("font-size", "12px")
        .style("fill", this.theme.palette.text.primary)
        .style("pointer-events", "none"); // Disable pointer events

      if (focus) {
        // Calculate the midpoint of the link
        const midX = (selectedLink.source.x + selectedLink.target.x) / 2;
        const midY = (selectedLink.source.y + selectedLink.target.y) / 2;

        // Zoom into the midpoint of the link
        const scale = 2; // Adjust the scale as needed
        const translateX = this.main.node().clientWidth / 2 - midX * scale;
        const translateY = this.main.node().clientHeight / 2 - midY * scale;
        this.main
          .transition()
          .duration(750)
          .call(
            this.zoom.transform,
            d3.zoomIdentity.translate(translateX, translateY).scale(scale),
          )
          .on("end", () => {
            // Re-enable zoom and pan behavior after the transition
            this.main.call(this.zoom);

            // Scroll to center the view
            const nodeElement = this.main.node();
            const nodeRect = nodeElement.getBoundingClientRect();
            const scrollX =
              window.scrollX +
              nodeRect.left +
              nodeRect.width / 2 -
              window.innerWidth / 2;
            const scrollY =
              window.scrollY +
              nodeRect.top +
              nodeRect.height / 2 -
              window.innerHeight / 2;
            window.scrollTo({
              top: scrollY,
              left: scrollX,
              behavior: "smooth",
            });
          });
      }
    }
  }

  deselectNode() {
    // Clear previous selections
    this.container.selectAll(".selected-circle").remove();
    this.container.selectAll(".node-id").remove();
    this.container
      .selectAll(".node-label")
      .style("fill", this.theme.palette.text.primary)
      .attr("fill-opacity", 0.4);
    this.setSelectedNode(null);
  }

  selectNode(nodeId: string, focus?: boolean) {
    if (this.nodes === null) {
      return;
    }
    // Clear previous selections
    this.deselectNode();

    // Find the node to select
    const selectedNode = this.nodes.find((node) => node.id === nodeId);

    if (selectedNode && selectedNode.x && selectedNode.y) {
      // call the setSelectedNode function
      this.setSelectedNode(selectedNode);
      // Highlight the node
      const nodeGroup = this.container.select(`[data-id='${selectedNode.id}']`);

      if (!nodeGroup.empty()) {
        nodeGroup.raise(); // Bring the selected node to the front
        nodeGroup
          .append("circle")
          .attr("class", "selected-circle")
          .attr("cx", selectedNode.x)
          .attr("cy", selectedNode.y)
          .attr("r", 12)
          .attr("fill", "none")
          .attr("stroke", this.theme.palette.success.main);

        // Change color and opacity for node-label
        nodeGroup
          .selectAll(".node-label")
          .attr("fill-opacity", 1)
          .style("fill", this.theme.palette.text.primary);

        // Append text for node label below the node id
        nodeGroup
          .append("text")
          .attr("class", "node-id")
          .text(selectedNode.id)
          .style("font-size", "12px")
          .style("fill", this.theme.palette.text.primary)
          .style("pointer-events", "none"); // Disable pointer events
      }

      if (focus) {
        // Calculate the center point of the node
        const centerX = selectedNode.x;
        const centerY = selectedNode.y;

        // Zoom into the center point of the node
        const scale = 2; // Adjust the scale as needed
        const translateX = this.main.node().clientWidth / 2 - centerX * scale;
        const translateY = this.main.node().clientHeight / 2 - centerY * scale;
        this.main
          .transition()
          .duration(750)
          .call(
            this.zoom.transform,
            d3.zoomIdentity.translate(translateX, translateY).scale(scale),
          )
          .on("end", () => {
            // Re-enable zoom and pan behavior after the transition
            this.main.call(this.zoom);

            // Scroll to center the view
            const nodeElement = this.main.node();
            const nodeRect = nodeElement.getBoundingClientRect();
            const scrollX =
              window.scrollX +
              nodeRect.left +
              nodeRect.width / 2 -
              window.innerWidth / 2;
            const scrollY =
              window.scrollY +
              nodeRect.top +
              nodeRect.height / 2 -
              window.innerHeight / 2;
            window.scrollTo({
              top: scrollY,
              left: scrollX,
              behavior: "smooth",
            });
          });
      }
    }
  }
}
