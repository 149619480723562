import React, { useState, useRef, useEffect } from "react";
import {
  CircularProgress,
  Alert,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Drawer,
  LinearProgress,
  Autocomplete,
  TextField,
  IconButton,
} from "@mui/material";
import {
  fetchPainData,
  fetchPainGraphData,
  PainGraphDataFlags,
  ValidPainGraphDataFlags,
} from "../utils/pain/painUI";

import {
  GraphData,
  GraphEdge,
  GraphNode,
  NetworkGraph,
  NodeIcon,
  Layout,
} from "../ui-components/networkGraph";
import SearchBox from "../ui-components/SearchBox";
import DropdownList from "../ui-components/DropDownList";
import { Equipment } from "../ui-components/Equipment";

import { Adjacency, GraphResponse } from "../gen/painClient";
import fetchPainAdjacencies, {
  NodeAdjacencies,
} from "../utils/pain/painAdjacencies";
import Clippy, {
  defaultRandomActionTime,
  ClippyProps,
} from "../ui-components/clippy";
import {
  ExtIdMap,
  fetchResourceEquipmentExternalIdsByExternalIds,
  GetPreferredNodeName,
} from "../utils/inventory/identify";
import {
  KeyboardArrowRight as KeyboardArrowRightIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  FitScreen as FitScreenIcon,
  Search as SearchIcon,
  DragIndicator as DragIndicatorIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import NodeListPanel, { EdgeListPanel } from "../ui-components/nodeListPanel";

import { toast } from "react-toastify";
import { useTheme } from "@mui/material/styles";
import { fetchResourceEquipmentById } from "../utils/inventory/equipment";
import { fetchVerifyCircuitData } from "../utils/pain/verifyCircuit";
import PQueue from "p-queue"; // Import PQueue for concurrency control
import { ResourceEquipment } from "../gen/cfsInventoryClient/types.gen";

import Draggable from "react-draggable";
import { LinkDetails } from "../ui-components/LinkDetails";
import { DraggableTile } from "../ui-components/DraggableTile";

type graphOptionFlag = {
  value: PainGraphDataFlags;
  label: string;
};
const graphOptionFlags: { value: PainGraphDataFlags; label: string }[] =
  ValidPainGraphDataFlags.map((flag) => ({
    value: flag as PainGraphDataFlags,
    label: flag,
  }));

const graphOptions = ["Graph", "AccessTree", "VerifyCircuit"];
const defaultGraphOption = "Graph";
const layoutOptions: Layout[] = ["Tree", "Radial", "ForceDirected", "Grid"];
const defaultLayout = "Tree" as Layout;
const defaultDepth = 0;
const depthValues = ["∞", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

export type adj = {
  local: Adjacency | undefined;
  adjacents: Adjacency[] | undefined;
};

type NodeDetailsMap = {
  [key: string]: ResourceEquipment | undefined;
};

export const PainVisualisation = () => {
  const [isInitializing, setIsInitializing] = useState(false);
  const [graphData, setGraphData] = useState<GraphData | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const graphRef = useRef<HTMLDivElement>(null);
  const [layout, setLayout] = useState<Layout>(defaultLayout);
  const [jumpToNeighborhood, setJumpToNeighborhood] = useState(false);
  const [highLightNode, setHighLightNode] = useState<GraphNode | undefined>(
    undefined,
  );
  const [highLightEdge, setHighLightEdge] = useState<GraphEdge | undefined>(
    undefined,
  );
  const [graphOption, setGraphOption] = useState<string>(defaultGraphOption);
  const [clippyState, setClippyState] =
    useState<ClippyProps["state"]>("pointRight");
  const [ClippyRandomActionTime, setClippyRandomActionTime] = useState(
    defaultRandomActionTime,
  );
  const [id, setId] = useState<string>("");
  const [extIds, setExtIds] = useState<ExtIdMap>({});
  const [nodeDetails, setNodeDetails] = useState<NodeDetailsMap>({});
  const [linkDetails, setLinkDetails] = useState<adj[]>([]);
  const [selectedNode, setSelectedNode] = useState<GraphNode | null>(null);
  const [selectedLink, setSelectedLink] = useState<GraphEdge | null>(null);
  const [nodeDrawerOpen, setNodeDrawerOpen] = useState(false);
  const [edgeDrawerOpen, setEdgeDrawerOpen] = useState(false);
  const [graphControls, setGraphControls] = useState<NetworkGraph | null>(null);
  const [depth, setDepth] = useState(defaultDepth);
  const [viewOnlyNeighborhood, setViewOnlyNeighborhood] = useState(false);
  const [selectedGraphOptionFlags, setSelectedGraphOptionFlags] = useState<
    graphOptionFlag[]
  >([]);

  const [progress, setProgress] = useState(0);
  const [totalNodes, setTotalNodes] = useState(0);
  const [completedNodes, setCompletedNodes] = useState(0);

  const [mousePosition, setMousePosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  const theme = useTheme();

  useEffect(() => {
    if (error) {
      toast.error(error);
      setClippyState("warning");
      setClippyRandomActionTime(0);
    } else if (loading) {
      setClippyState("looking");
      setClippyRandomActionTime(0);
    } else if (success) {
      setClippyState("success");
      setClippyRandomActionTime(0);
    } else {
      setClippyState("pointRight");
      setClippyRandomActionTime(defaultRandomActionTime);
    }
  }, [error, loading]);

  useEffect(() => {
    if (totalNodes > 0) {
      setProgress((completedNodes / totalNodes) * 100);
    }
  }, [completedNodes, totalNodes]);

  const getValidNodeId = (id: string): string => {
    // Strip extra information from node id
    if (id.includes("\n")) {
      const splitId = id.split("\n");
      return splitId[0];
    }
    return id;
  };

  const handleSearch = () => {
    setTotalNodes(0);
    setCompletedNodes(0);
    setProgress(0);
    graphControls?.destroyNetwork();
    setGraphControls(null);
    let painGraph: GraphResponse | undefined = {};
    const extIds: ExtIdMap = {};
    const nodeDetails = {};
    if (graphOption !== "Graph" && !id) {
      return;
    }

    const fetchPainGraph = async () => {
      const options = selectedGraphOptionFlags.map((flag) => flag.value);
      painGraph = await fetchPainGraphData(options);
      if (!painGraph) {
        setError("Error fetching Graph data");
      }
    };
    const fetchPain = async () => {
      painGraph = await fetchPainData(id);
      if (!painGraph) {
        setError("Error fetching Graph data");
      }
    };
    const fetchVerifyCircuit = async () => {
      painGraph = await fetchVerifyCircuitData(id);
      if (!painGraph) {
        setError("Error fetching Graph data");
      }
    };

    const fetchAllNodeDetails = async () => {
      const queue = new PQueue({ concurrency: 50 }); // Limit concurrency to 50

      const fetchId = async (id) => {
        if (extIds[id]) {
          setCompletedNodes((prev) => prev + 1);
          return;
        }
        if (id === "") {
          setCompletedNodes((prev) => prev + 1);
          return;
        }
        const ids = await fetchResourceEquipmentExternalIdsByExternalIds([
          getValidNodeId(id),
        ]);
        if (!ids) {
          console.error("Could not find external IDs for:", id);
          setCompletedNodes((prev) => prev + 1);
          return;
        }
        extIds[id] = ids;
        // Use inventory ID to fetch node details
        const inventoryRecord = ids.find(
          (ext) => ext.system_name === "INVENTORY",
        );
        if (inventoryRecord && inventoryRecord.external_id) {
          const invId = await fetchResourceEquipmentById(
            inventoryRecord.external_id,
          );
          nodeDetails[id] = invId;
        }
        setCompletedNodes((prev) => prev + 1);
      };

      setTotalNodes(painGraph?.nodes?.length ?? 0);
      for (const node of painGraph?.nodes ?? []) {
        const id = node.id ?? "";
        queue.add(() => fetchId(id));
      }
      await queue.onIdle(); // Wait for all tasks to complete
      setExtIds(extIds);
      setNodeDetails(nodeDetails);
    };

    const fetchGraphData = async () => {
      switch (graphOption) {
        case "Graph":
          await fetchPainGraph();
          break;
        case "AccessTree":
          await fetchPain();
          break;
        case "VerifyCircuit":
          await fetchVerifyCircuit();
          break;
        default:
          setError("Invalid graph option");
          break;
      }
    };

    const addNodeDetails = async () => {
      const graphData: GraphData = { title: "", nodes: [], edges: [] };
      const selectNodeIcon = (id: string): NodeIcon => {
        if (nodeDetails[id] && nodeDetails[id].capability) {
          // Find node icon based on highest priority capability
          for (const capability of nodeDetails[id].capability) {
            switch (capability) {
              case "L3_INTERNET":
              case "L3_VPN":
                return "serviceRouter";
              case "L2":
                return "mplsRouter";
              case "ACCESS":
                return "switch";
            }
          }
        }
        return "unknown";
      };

      const selectNodeWeight = (id: string): number => {
        if (nodeDetails[id] && nodeDetails[id].capability) {
          // Find node weight based on highest priority capability
          for (const capability of nodeDetails[id].capability) {
            switch (capability) {
              case "L3_INTERNET":
              case "L3_VPN":
                return 6;
              case "L2":
                return 4;
              case "ACCESS":
                return 2;
            }
          }
        }
        if (nodeDetails[id] && nodeDetails[id].host_name) {
          if (nodeDetails[id].host_name.includes("-CR")) {
            return 10;
          }
          if (nodeDetails[id].host_name.includes("-PE")) {
            return 6;
          }
          if (nodeDetails[id].host_name.includes("-AGG")) {
            return 4;
          }
          if (nodeDetails[id].host_name.includes("-ACC")) {
            return 2;
          }
        }
        return 0;
      };

      const selectNodeGroup = (id: string): string => {
        return id.split("-")[0];
      };
      for (const node of painGraph?.nodes ?? []) {
        const graphNode = node as GraphNode;
        const id = node.id ?? "";
        graphNode.label = id;
        if (extIds[id]) {
          graphNode.label = GetPreferredNodeName(id, extIds);
        }
        if (!nodeDetails[id]) {
          console.log("No node details for:", id);
          // continue;
        }
        graphNode.label = GetPreferredNodeName(id, extIds);
        graphNode.icon = selectNodeIcon(id);
        graphNode.id = id;
        graphNode.title = node.title;
        graphNode.color = node.color;
        graphNode.x = node.x;
        graphNode.y = node.y;
        graphNode.physics = node.physics;
        graphNode.weight = selectNodeWeight(id);
        graphNode.group = selectNodeGroup(id);

        graphData.nodes.push(graphNode);
      }
      let edgeId = 0;
      for (const edge of painGraph?.edges ?? []) {
        const graphEdge = edge as GraphEdge;
        graphEdge.from = edge.from ?? "";
        graphEdge.to = edge.to ?? "";
        graphEdge.source = graphEdge.from;
        graphEdge.target = graphEdge.to;
        graphEdge.label = edge.label;
        graphEdge.title = edge.title;
        graphEdge.color = edge.color;
        graphEdge.width = edge.width;
        graphEdge.id = edgeId.toString();
        edgeId++;

        graphData.edges.push(graphEdge);
      }
      if (graphData) {
        setGraphData(graphData);
      } else {
        setError("Error adding node details");
      }
    };

    const load = async () => {
      setLoading(true);
      setError(null);
      await fetchGraphData();
      await fetchAllNodeDetails();
      await addNodeDetails();
      setLoading(false);
      setSuccess(true);
    };

    load();
  };

  const handleSelectNodeInView = (node: GraphNode) => {
    setJumpToNeighborhood(false);
    setHighLightNode(node);
  };

  const handleSelectNodeOutOfView = (node: GraphNode) => {
    setJumpToNeighborhood(true);
    setHighLightNode(node);
  };

  const handleSelectEdge = (edge: GraphEdge) => {
    setJumpToNeighborhood(false);
    setHighLightEdge(edge);
  };

  const handleSelectGraphOptionFlagsChange = (_, value) => {
    setSelectedGraphOptionFlags(value);
  };

  const toggleNodeDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setNodeDrawerOpen(open);
    };

  const toggleEdgeDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setEdgeDrawerOpen(open);
    };

  useEffect(() => {
    const getAdjacencies = async (source: string, dest: string) => {
      const linkDetails: adj[] = [];
      const adjacencies = await fetchPainAdjacencies(source);
      for (const adj of adjacencies) {
        for (const adjNode of adj.adjacents ?? []) {
          if (adjNode.node === dest) {
            linkDetails.push(adj);
          }
        }
      }
      setLinkDetails(linkDetails);
    };
    if (selectedLink?.source?.id && selectedLink?.target?.id) {
      getAdjacencies(
        getValidNodeId(selectedLink.source.id),
        getValidNodeId(selectedLink.target.id),
      );
    }
  }, [selectedLink]);

  const getNodeName = (id: string) => {
    const extId = extIds[id];
    let dns: string | undefined;
    let mgtmt: string | undefined;
    if (extId) {
      for (const id of extId) {
        if (id.system_name === "DNS") {
          dns = id.external_id;
        }
        if (id.system_name === "MANAGEMENT") {
          mgtmt = id.external_id;
        }
      }
      if (dns) {
        return dns;
      }
      if (mgtmt) {
        return mgtmt;
      }
      return id;
    }
    return id;
  };

  // Draw network
  useEffect(() => {
    const initializeNetwork = async () => {
      if (isInitializing || !graphData) {
        return;
      }
      if (!graphControls) {
        setIsInitializing(true);
        const graph = new NetworkGraph({
          graphData,
          graphRef,
          setSelectedLink,
          setSelectedNode,
          layout,
          theme,
          viewOnlyNeighborhood,
          focusedNode: id,
          depth,
        });
        setGraphControls(graph);
        setIsInitializing(false);
        graph.renderNetwork();
      } else {
        graphControls.reRenderNetwork({ layout, theme, viewOnlyNeighborhood });
      }
    };
    initializeNetwork();
  }, [graphData, layout, theme, isInitializing]);

  // Update neighborhood and depth
  useEffect(() => {
    if (graphControls) {
      graphControls.setViewOnlyNeighborhood(viewOnlyNeighborhood);
      graphControls.setDepth(depth);
      if (viewOnlyNeighborhood) {
        graphControls.viewNeighborhood(selectedNode?.id ?? "");
        return;
      }
      graphControls.reRenderNetwork({ layout, theme, viewOnlyNeighborhood });
    }
  }, [viewOnlyNeighborhood, depth]);

  // Highlight node
  useEffect(() => {
    if (graphControls && highLightNode) {
      if (viewOnlyNeighborhood && jumpToNeighborhood) {
        graphControls.viewNeighborhood(highLightNode?.id ?? "");
        return;
      }
      graphControls.selectNode(highLightNode?.id ?? "", true);
    }
  }, [highLightNode]);

  // Highlight edge
  useEffect(() => {
    if (graphControls && highLightEdge) {
      graphControls.selectEdge(highLightEdge?.id ?? "", true);
    }
  }, [highLightEdge]);

  // Record where mouse is to position draggable tiles
  const handleMouseMove = (event: React.MouseEvent) => {
    const offsetX =
      event.clientX - event.currentTarget.getBoundingClientRect().right / 2;
    const offsetY =
      event.clientY - event.currentTarget.getBoundingClientRect().top;
    setMousePosition({ x: offsetX, y: offsetY });
  };

  const graphView = () => {
    if (!graphData) {
      return null;
    }
    return (
      <div
        onMouseMove={handleMouseMove}
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ position: "fixed", left: 0, top: "50%" }}>
          <Button
            onClick={toggleNodeDrawer(true)}
            style={{
              minWidth: "auto", // Ensure the button only takes up the space of the icon
              padding: 0, // Remove padding to fit the icon size
            }}
          >
            <KeyboardArrowRightIcon sx={{ fontSize: 50 }} />
          </Button>
        </div>
        <Drawer
          anchor="left"
          open={nodeDrawerOpen}
          onClose={toggleNodeDrawer(false)}
          variant="temporary"
          PaperProps={{
            style: {
              width: "auto", // Adjust the width to fit the content
              maxHeight: "100vh", // Ensure the drawer doesn't exceed the viewport height
              overflowY: "auto", // Enable vertical scrolling if needed
            },
          }}
        >
          <NodeListPanel
            nodes={graphData?.nodes ?? []}
            inViewNodes={
              graphControls?.getFilteredNodes() ?? ([] as GraphNode[])
            }
            onSelectNode={handleSelectNodeOutOfView}
            onselectNodeinView={handleSelectNodeInView}
            toggleDrawer={setNodeDrawerOpen}
          />
        </Drawer>
        <div style={{ position: "fixed", right: 0, top: "50%" }}>
          <Button
            onClick={toggleEdgeDrawer(true)}
            style={{
              minWidth: "auto", // Ensure the button only takes up the space of the icon
              padding: 0, // Remove padding to fit the icon size
            }}
          >
            <KeyboardArrowLeftIcon sx={{ fontSize: 50 }} />
          </Button>
        </div>
        <Drawer
          anchor="right"
          open={edgeDrawerOpen}
          onClose={toggleEdgeDrawer(false)}
          variant="temporary"
          PaperProps={{
            style: {
              width: "auto", // Adjust the width to fit the content
              maxHeight: "100vh", // Ensure the drawer doesn't exceed the viewport height
              overflowY: "auto", // Enable vertical scrolling if needed
            },
          }}
        >
          <EdgeListPanel
            edges={graphData?.edges ?? []}
            inViewEdges={
              graphControls?.getFilteredEdges() ?? ([] as GraphEdge[])
            }
            onSelectEdge={handleSelectEdge}
            toggleDrawer={setEdgeDrawerOpen}
          />
        </Drawer>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <DraggableTile
            title={selectedNode?.id ?? ""}
            hiddenWhenEmpty={true}
            defaultPosition={{
              x: mousePosition.x || -300,
              y: mousePosition.y || 0,
            }}
          >
            {selectedNode && (
              <Equipment id={nodeDetails[selectedNode.id]?.id ?? ""} />
            )}
          </DraggableTile>
          <DraggableTile
            title={selectedLink?.label ?? ""}
            hiddenWhenEmpty={true}
            defaultPosition={{
              x: mousePosition.x || 50,
              y: mousePosition.y || 0,
            }}
          >
            {selectedLink && (
              <LinkDetails
                linkDetails={linkDetails}
                getNodeName={getNodeName}
              />
            )}
          </DraggableTile>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "20px 0",
          }}
        >
          <Button
            onClick={() => {
              graphControls?.fitScreen();
            }}
          >
            <FitScreenIcon />
          </Button>
        </div>
        <div
          ref={graphRef}
          style={{
            flex: "1 1 auto",
            height: "calc(100% - 50px)",
            width: "calc(100% - 50px)",
            overflow: "auto",
          }}
        />
      </div>
    );
  };

  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          flex: "1 0 auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1>Pain Visualisation</h1>

        {totalNodes > 0 && completedNodes != totalNodes && (
          <div>
            Hydrating nodes: {completedNodes}/{totalNodes}
            <LinearProgress variant="determinate" value={progress} />
          </div>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px", // Add some space between the components
          }}
        >
          <div>
            <Clippy
              state={clippyState}
              randomActionTime={ClippyRandomActionTime}
            />
          </div>
          {graphOption !== "Graph" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <label>Search:</label>
              <SearchBox
                placeholder="Cable, Circuit or Node..."
                searchTerm={id}
                setSearchTerm={setId}
                handleSearch={handleSearch}
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "200px",
                }}
              >
                <label style={{ width: "100%" }}>
                  <Autocomplete
                    multiple
                    options={graphOptionFlags}
                    getOptionLabel={(option) => option.label}
                    value={selectedGraphOptionFlags}
                    onChange={handleSelectGraphOptionFlagsChange}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Select Graph Options"
                        placeholder="Select Graph Options"
                        style={{ width: "100%" }}
                      />
                    )}
                    style={{ width: "100%" }}
                  />
                </label>
              </div>
              <SearchIcon onClick={handleSearch} />
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <label>Graph:</label>
            <DropdownList
              options={graphOptions}
              selectedOption={graphOption}
              setSelectedOption={setGraphOption}
              placeholder="Select Graph"
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <label>Layout:</label>
            <DropdownList
              options={layoutOptions}
              selectedOption={layout}
              setSelectedOption={(value: string) => setLayout(value as Layout)}
              placeholder="Select Layout"
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            Depth
            <DropdownList
              options={depthValues}
              selectedOption={depth === 0 ? "∞" : depth.toString()}
              setSelectedOption={(value: string) => {
                if (value === "∞") {
                  setDepth(0);
                  setViewOnlyNeighborhood(false);
                  return;
                }
                setDepth(parseInt(value));
                setViewOnlyNeighborhood(true);
              }}
              placeholder="Select Depth"
            />
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        ></div>
        {graphView()}
      </div>
    </div>
  );
};

export const PainAdjacency = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const inputRef = useRef<HTMLInputElement>(null); // Create a ref
  const [searchResult, setSearchResult] = useState<NodeAdjacencies | null>(
    null,
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleSearch = async (term: string) => {
    setLoading(true);
    setErrorMessage(null);
    try {
      setSearchResult(null); // set search result to null before search
      term = term
        .split(" ")
        .map((word) => word.toUpperCase())
        .join(" ");

      const adjacencies = await fetchPainAdjacencies(term, true);
      setSearchResult(adjacencies || null); // Provide a default value of null if response?.addressInfo is undefined
      setErrorMessage(null);
    } catch (error) {
      console.error(`Error: ${error} `);
      setErrorMessage(`Error: ${error} `);
    } finally {
      setLoading(false);
    }
  };

  const handleNodeClick = (node: string) => {
    setSearchTerm(node);
    handleSearch(node);
  };

  return (
    <div>
      <h1>Pain Adjacency</h1>
      <SearchBox
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleSearch={() => handleSearch(searchTerm)}
        inputRef={inputRef}
        placeholder="Enter TM node ID..."
      />
      {loading ? <CircularProgress /> : null}
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      {searchResult && searchResult.length > 0 && (
        <>
          <h2>Adjacencies for {searchTerm}</h2>
          <Alert severity="warning">
            Port numbers from Telemator may be 1 lower in real life.
          </Alert>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Port</TableCell>
                  <TableCell>Circuit ID</TableCell>
                  <TableCell>Circuit Type</TableCell>
                  <TableCell>Adjacent Node</TableCell>
                  <TableCell>Adjacent Port</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchResult.map((result, index) => (
                  <React.Fragment key={index}>
                    <TableRow>
                      <TableCell rowSpan={result.adjacents?.length}>
                        {result.local?.card}/{result.local?.port}
                      </TableCell>
                      <TableCell rowSpan={result.adjacents?.length}>
                        {result.local?.circuit?.id}
                      </TableCell>
                      <TableCell rowSpan={result.adjacents?.length}>
                        {result.local?.circuit?.type}
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={() => {
                            if (result.adjacents && result.adjacents[0]?.node) {
                              handleNodeClick(result.adjacents[0].node);
                            }
                          }}
                        >
                          {result.adjacents && result.adjacents[0]?.node}
                        </Button>
                      </TableCell>
                      <TableCell>
                        {result.adjacents
                          ? `${result.adjacents[0]?.card}/${result.adjacents[0]?.port}`
                          : null}
                      </TableCell>
                    </TableRow>
                    {result.adjacents?.slice(1).map((adjacent, adjIndex) => (
                      <TableRow key={adjIndex}>
                        <TableCell>
                          <Button
                            onClick={() => {
                              if (adjacent.node) {
                                handleNodeClick(adjacent.node);
                              }
                            }}
                          >
                            {adjacent.node}
                          </Button>
                        </TableCell>
                        <TableCell>
                          {adjacent.card}/{adjacent.port}
                        </TableCell>
                      </TableRow>
                    ))}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
};

export const Pain = () => {
  return <PainVisualisation />;
};

export default PainAdjacency;