import { configureOpenAPI } from "../../utils/openApi";
import { OpenAPI, getPainData, GraphResponse, getShowGraphData, GetShowGraphDataData } from "../../gen/painClient";
import { toast } from "react-toastify";

 
export const fetchPainData = async (
    id: string,
): Promise<GraphResponse | undefined> => {
    try {
        await configureOpenAPI(OpenAPI);
        const response = await getPainData({id, stopAtCoreSite: true});
        return response;
    } catch (error) {
        toast.error("Error fetching pain data");
        return undefined;
    }
};

export type PainGraphDataFlags = keyof GetShowGraphDataData;
export const ValidPainGraphDataFlags: PainGraphDataFlags[] = [
    "pruneedgeb2B",
    "pruneedgekbo",
    "pruneleaves",
    "prunenodeb2B",
    "prunenodekbo",
    "prunenodeboth",
];

export const fetchPainGraphData = async (
    flags: PainGraphDataFlags[],
): Promise<GraphResponse | undefined> => {
    try {
        await configureOpenAPI(OpenAPI);
        const options = flags.reduce((acc, flag) => {
                acc[flag] = true;
            return acc;
        }, {} as Record<PainGraphDataFlags, boolean>);

        const response = await getShowGraphData(options);
        return response;
    } catch (error) {
        toast.error("Error fetching pain Graph data");
        return undefined;
    }
}
    

export default fetchPainData;
