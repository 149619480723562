import { configureOpenAPI } from "../../utils/openApi";
import { OpenAPI, getVerifyCircuitGraph, GraphResponse } from "../../gen/painClient";
import { toast } from "react-toastify";

 
export const fetchVerifyCircuitData = async (
    id: string,
): Promise<GraphResponse | undefined> => {
    try {
        await configureOpenAPI(OpenAPI);
        const response = await getVerifyCircuitGraph({
            id,
            returnGraph: true,
            stopAtCoreSite: false,
        });
        return response;
    } catch (error) {
        toast.error("Error fetching pain data");
        return undefined;
    }
};
    

export default fetchVerifyCircuitData;
