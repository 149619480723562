import { toast } from "react-toastify";
import { OpenAPI as CustLineOpenAPI } from "../gen/cfsCustlineClient/core/OpenAPI";
import { OpenAPI as InternetOpenAPI } from "../gen/cfsInternetClient/core/OpenAPI";
import { OpenAPI as IpVpnOpenAPI } from "../gen/cfsIpVpnClient/core/OpenAPI";
import { OpenAPI as L2CircuitOpenAPI } from "../gen/cfsL2CircuitClient/core/OpenAPI";
import { OpenAPI as BandwidthOpenAPI } from "../gen/bandwidthClient/core/OpenAPI";
import { OpenAPI as InventoryOpenAPI } from "../gen/cfsInventoryClient/core/OpenAPI";
import { OpenAPI as PainOpenAPI } from "../gen/painClient/core/OpenAPI";
import {
    bandwidthConfig,
    custlineConfig,
    internetConfig,
    ipVpnConfig,
    l2CircuitConfig,
    inventoryConfig,
    painConfig,
    loginRequest,
    storageConfig,
  } from "../authConfig";
import { acquireAccessToken } from "../utils/auth";
import { fetchApiKey } from "./MsGraphApiCall";
import { msalInstance } from "..";

export const configureOpenAPI = async (
    OpenAPI:
      | typeof CustLineOpenAPI
      | typeof InternetOpenAPI
      | typeof IpVpnOpenAPI
      | typeof L2CircuitOpenAPI
      | typeof BandwidthOpenAPI
      | typeof InventoryOpenAPI
      | typeof PainOpenAPI,
  ) => {
    let scope: string|string[] = [];
    switch (OpenAPI) {
      case CustLineOpenAPI:
        OpenAPI = CustLineOpenAPI;
        OpenAPI.BASE = custlineConfig.baseUrl;
        scope = Array.isArray(custlineConfig.scope) ? custlineConfig.scope : [custlineConfig.scope];
        break;
      case InternetOpenAPI:
        OpenAPI = InternetOpenAPI;
        OpenAPI.BASE = internetConfig.baseUrl;
        scope = Array.isArray(internetConfig.scope) ? internetConfig.scope : [internetConfig.scope];
        break;
      case IpVpnOpenAPI:
        OpenAPI = IpVpnOpenAPI;
        OpenAPI.BASE = ipVpnConfig.baseUrl;
        scope = Array.isArray(ipVpnConfig.scope) ? ipVpnConfig.scope : [ipVpnConfig.scope];
        break;
      case L2CircuitOpenAPI:
        OpenAPI = L2CircuitOpenAPI;
        OpenAPI.BASE = l2CircuitConfig.baseUrl;
        scope = Array.isArray(l2CircuitConfig.scope) ? l2CircuitConfig.scope : [l2CircuitConfig.scope];
        break;
      case BandwidthOpenAPI:
        OpenAPI = BandwidthOpenAPI;
        OpenAPI.BASE = bandwidthConfig.baseUrl;
        scope = Array.isArray(bandwidthConfig.scope) ? bandwidthConfig.scope : [bandwidthConfig.scope];
        break;
        case InventoryOpenAPI:
        OpenAPI = InventoryOpenAPI;
        OpenAPI.BASE = inventoryConfig.baseUrl;
        scope = Array.isArray(inventoryConfig.scope) ? inventoryConfig.scope : [inventoryConfig.scope];
        break;
      case PainOpenAPI:
        OpenAPI = PainOpenAPI;
        OpenAPI.BASE = painConfig.baseUrl;
        OpenAPI.HEADERS = {};
        let apiKey = "";
        const setApiKey = (key) => {
          apiKey = key;
        }
        await fetchApiKey(
          msalInstance,
          loginRequest,
          storageConfig,
          setApiKey,
          "pain_api_key",
        );
        OpenAPI.HEADERS["X-Api-Key"] = apiKey;
        return; // No need to acquire access token for Pain API
      default:
        throw new Error("Invalid OpenAPI instance");
    }
    try {
      const token = await acquireAccessToken(scope);
      OpenAPI.TOKEN = token;
    } catch (error) {
      console.error("Failed to configure OpenAPI", error);
      toast.error(
        `Unable to acquire access token for ${typeof OpenAPI}: ${error.message}`,
      );
      throw new Error("Failed to configure OpenAPI");
    }
  };